import { apiInstance } from "../apiInstance";
import { protectedResources } from "../../msalAuthConfig";

const headers = {
    'Content-Type': 'application/json',
};

const NonMemberApi = {
    GetNonMembersByAgId: async (agId) => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/${agId}/non-member-roster`,
            headers,
        })
    },
    AddNonMember: async (data) => {
        return apiInstance({
            method: 'POST',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/non-member`,
            headers,
            data,
        })
    },
    UpdateNonMember: async (data) => {
        return apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/non-member/${data.id}/roster`,
            headers,
            data,
        })
    },
    GetNonMemberAttendanceByMeetingId: async (agId, meetingId) => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/${agId}/meeting/${meetingId}/non-member`,
            headers,
        })
    },
    AddNonMemberAttendance: async (data) => {
        return apiInstance({
            method: 'POST',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/non-member/attendance-log`,
            headers,
            data,
        })
    },
    UpdateNonMemberAttendance: async (data) => {
        return apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/non-member/attendance-log/${data.id}`,
            headers,
            data,
        })
    }
}

export default NonMemberApi;
