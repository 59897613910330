import { AGGREGATE_SCORES, AGGREGATE_SCORES_COLOR } from "../../constants";

const GridCellRating = ({ rating, customClass, children }) => {
    return (
        <span
            className={`d-inline-block fw-bold px-2 ${AGGREGATE_SCORES_COLOR[rating]} ${customClass ?? ''}`}>
            {rating === 'Loading...' ? rating : children || AGGREGATE_SCORES[rating]}
        </span>
    );
};

export default GridCellRating;
