import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { AG_MEETING_STATUS_INVERSE, ALERT_TYPE, ALIGN, POSITIONS, YES_NO_INVERSE } from '../../../../constants';
import SharedForm from '../../../../components/ui/Form';
import { radioOptionValues } from '../../../../utils/utils';
import { getAdvisoryGroupMeetingDetails, updateAttendanceLogStatus, updateMeetingStatus } from '../../../../ducks/advisoryGroup';
import { AlertContext } from '../../../../context/AlertContext';
import messages from '../../../../utils/helper/messages';

const CancelMeetingModal = ({ show, onCancel, meetingId }) => {
    const dispatch = useDispatch();
    const { showAlert } = useContext(AlertContext);

    const currentUser = useSelector((state) => state.user.currentUser);

    const arr = [
        {
            key: 'confirmation',
            labelName: 'Confirmation',
            type: 'radio',
            optionValues: radioOptionValues(YES_NO_INVERSE, true),
            maxLength: 12
        },
        {
            key: 'reason',
            labelName: 'Reason',
            placeholderName: 'Reason',
            type: 'text',
            maxLength: 65,
            conditional: true,
            disabled: true,
            required: true,
            dependsOn: 'confirmation',
            dependsValue: [YES_NO_INVERSE.Yes]
        },
    ];

    const handleSuccess = () => {
        const attendanceData = {
            meetingId: meetingId,
            recordStatus: AG_MEETING_STATUS_INVERSE.Cancelled
        };
        
        showAlert(messages.success.cancelMeeting, ALERT_TYPE.SUCCESS);
        dispatch(getAdvisoryGroupMeetingDetails(meetingId));
        dispatch(updateAttendanceLogStatus(attendanceData, handleError));
        onCancel();
    };

    const handleError = (error) => {
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    };

    const handleSubmit = (formData) => {
        if (!formData) return;
        
        if (formData.confirmation === YES_NO_INVERSE.Yes) {
            const meetingData = {
                meetingId: meetingId,
                lastModifiedUser: currentUser?.id,
                status: AG_MEETING_STATUS_INVERSE.Cancelled,
                cancellationReason: formData.reason
            };
            
            dispatch(updateMeetingStatus(meetingData, handleSuccess, handleError));
        } else {
            onCancel();
        }
    };

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onCancel}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>Cancel Meeting</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        onSubmit={handleSubmit}
                        onCancel={onCancel}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText="Continue"
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CancelMeetingModal;