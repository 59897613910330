const messages = {
    success: {
        // Company
        createCompany: 'Company created successfully',
        updateCompany: 'Company updated successfully',
        deleteCompany: 'Company deleted successfully',

        // User
        createUser: 'User created successfully',
        updateUser: 'User profile updated successfully',
        archiveUser: 'User archived successfully',
        unarchiveUser: 'User unarchived successfully',
        inviteUser: 'User invited successfully',

        // Content
        createContent: 'Content created successfully',
        updateContent: 'Content updated successfully',
        archiveContent: 'Content archived successfully',

        // Cohort
        cohortUpload: 'uploaded successfully',
        cohortExport: 'File downloaded successfully',
        deleteCohort: 'Cohort archived successfully',
        deleteNotification: 'Notification archived successfully',
        updateCohortMember: 'Member updated successfully',
        mergeCohortMember: 'Cohort merged successfully',
        scheduleCohort: 'Cohort scheduled successfully',

        // Program management
        archiveProgram: 'Program archived successfully',
        copyProgram: 'Program copied successfully',
        addProgram: 'New program created successfully',
        updateProgram: 'Program updated successfully',

        //System Notifications
        archiveNotifications: 'Notifications archived successfully',

        // Advisory Group
        updateMeeetingDetails: 'Meeting details updated successfully',
        addMeetingLocation: 'Meeting location added successfully',
        updateMeetingLocation: 'Meeting location updated successfully',
        updateMeetingPreResponseStatus: 'Pre-meeting response updated successfully',
        updateMeetingAttendancePostStatus: 'Meeting attendance status updated successfully',
        updateMeetingStatusDocumented: 'Meeting documentation status updated successfully',
        addAttendanceLog: 'Attendance details added successfully',
        updateAttendanceLogData: 'Attendance details updated successfully',
        updateAdvisoryGroupDetail: 'Advisory Group details updated successfully',
        addPortal: 'Advisory Group portal details added successfully',
        updatePortal: 'Advisory Group portal details updated successfully',
        archiveRecruitmentMembers: 'Members archived successfully',
        addRosterComment: 'Comment added successfully',
        AddMeeting: 'Meeting added successfully',
        addMeetingLocationSupportStaff: 'Meeting location host added successfully',
        addCaregiver: 'Caregiver added successfully',
        updateCaregiver: 'Caregiver updated successfully',
        archiveCaregiver: 'Caregiver archived successfully',
        caregiverStatus: 'Caregiver details updated successfully',
        cancelMeeting: 'Meeting cancelled successfully.',

        // AG Recruitment Application
        updateMemberRecord: 'Member record updated successfully',
        updateApplication: 'Recruitment Application updated successfully',
        addComment: 'Member comment added successfully',
        updateRecruitingOutreach: 'Recruitment Outreach updated successfully',
        deleteOutreachComment: 'Comment deleted successfully',
        addOutreachComment: 'Comment added successfully',
        deleteMemberComment: 'Member comment deleted successfully',

        // Panel Selection and Maintenance
        updatePanelSelection: 'Panel selection updated successfully',
        noUpdatesPanelSelection: 'Panel selection updates have been discarded',
        noUpdatesProcessApplication: 'Application process updates have been discarded',

        // Panel Maintenance
        updatePanelMaintenance: 'Panel updated successfully',
        noUpdatesPanelMaintenance: 'Panel updates have been discarded',

        // Meeting
        archiveMeetingLocation: 'Meeting location cancelled successfully',

        // Support Staff
        addSupportStaff: 'Support staff record added successfully',
        updateSupportStaff: 'Support staff record updated successfully',
        archiveSupportStaffMembers: 'Support staff member archived successfully',
        addSupportStaffComment: 'Comment added successfully',
        supportStaffAssign: 'Support staff successfully assigned to the meeting',
        updateSupportStaffAssigned: 'Assigned support staff updated successfully',
        cancelSupportStaffAssigned: 'Assigned support staff cancelled successfully',

        // Non Members
        addNonMember: 'Non-Member added successfully',
        updateNonMember: 'Non-Member updated successfully',
        archiveNonMember: 'Non-Member archived successfully',
        addNonMemberAttendance: 'Non-Member attendance added successfully',
        updateNonMemberAttendance: 'Non-Member attendance updated successfully',
    },
    error: {
        // General
        prefix: 'Error:',

        // Cohort
        cohortUpload: 'Error in uploading',
        cohortNameValidation: 'Cohort name should not be empty and should not have a leading or trailing white space or multiple spaces.',
        userDetailsValidation: 'User details should not be empty.',
        cohortMemberInvalid: 'Cohort member id does not exist',

        // Program management
        copyProgram: 'Error copying program',
        addProgram: 'Error creating new program',
        updateProgram: 'Error updating program',
        updateAdvisoryGroup: 'Error updating Advisory Group',

        //System Notifications
        archiveNotifications: 'Error archiving notifications',

        // Support Staff
        locationAssign: 'Location not assigned to all selected support staff',
    },
    confirm: {
        // Company
        deleteCompany: 'Are you sure you want to delete the company?',

        // Content
        archiveContent: 'Are you sure you want to archive the content?',

        // User
        archiveUser: 'Are you sure you want to archive the user?',
        unarchiveUser: 'Are you sure you want to unarchive the user?',

        // Cohort
        deleteCohort: 'Are you sure you want to archive the cohort?',
        deleteNotification: 'Are you sure you want to archive the notification?',

        // Program
        archiveProgram: 'Are you sure you want to archive the program?',
        discardProgramEdit: 'All unsaved changes will be lost. Are you sure you want to continue?',

        // System notifications
        archiveNotifications: 'Are you sure you want to archive these notifications?',

        // Meeting
        archiveMeetingLocation: 'Are you sure you want to archive the meeting location?',

        // Advisory Group
        archiveRecruitmentMember: 'Are you sure you want to archive these members?',

        // Support Staff
        archiveSupportStaff: `Selected records will be archived.`,
        discardSupportStaffAssign: 'All unsaved changes will be lost. Are you sure you want to continue?',
        cancelSupportStaff: 'Are you sure you want to remove the support staff from the meeting?',

        // Caregiver
        archiveCaregiver: 'Are you sure you want to archive the Caregiver?',

        // Non Members
        archiveNonMember: 'Are you sure you want to archive the non member?',
    },
    info: {
        // Cohort
        mergeCohortNoRecord: 'No validated cohorts available',
        agRecruitingAlert: 'Currently no members are available to invite or onboard. Please go to the Panel Selection page and select members to add to your AG',
    }
}

export default messages;
