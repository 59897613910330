import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { archiveCohortNotifications, getCohortNotifications } from "../../../ducks/cohort";
import { AppLoadContext } from '../../../components/ui/AppLoadContext';
import DataGrid from '../../../components/DataGrid';
import withAuth from '../../../components/hoc/withAuth';
import Loader from '../../../components/ui/Loader';
import { dateObjectEquals, toDateTime } from "../../../utils/utils";
import { NOIFICATION_TYPE, NOIFICATION_APPLICATION, ALERT_TYPE } from "../../../constants";
import ActionBar from "../../../components/ui/ActionBar";
import ConfirmModal from "../../../components/ui/ConfirmModal";
import messages from "../../../utils/helper/messages";
import { Button } from 'react-bootstrap';
import { AlertContext } from "../../../context/AlertContext";
import OverflowText from '../../../components/ui/OverflowTooltip';

export const SystemNotification = () => {
    const dispatch = useDispatch();
    const isAppLoaded = useContext(AppLoadContext);
    const { showAlert } = useContext(AlertContext);
    const notifications = useSelector((state) => state.cohort.notifications);
    const currentUser = useSelector((state) => state.user.currentUser);
    const [masterChecked, setMasterChecked] = useState(false);
    const [checkedIds, setCheckedIds] = useState([]);
    const [confirmModal, setConfirmModal] = useState(false);

    const defaultSort = [
        {
            id: 'dateTime',
            desc: true,
        },
    ];
    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getCohortNotifications(currentUser?.companyId));
        }
        // Safe to add dispatch to the dependencies array
    }, [dispatch, isAppLoaded, currentUser]);

    //Archive
    const handleConfirmClose = () => {
        setConfirmModal(false);
    }
    const handleConfirm = () => {
        try {
            dispatch(archiveCohortNotifications({ idList: checkedIds }, () => {
                showAlert(messages.success.archiveNotifications, ALERT_TYPE.SUCCESS);
                setCheckedIds([]);
                dispatch(getCohortNotifications(currentUser?.companyId));
            }));
        } catch (error) {
            showAlert(`${messages.error.archiveNotifications} ${error}.`, ALERT_TYPE.ERROR);
        }
        handleConfirmClose();
    }
    const handleArchiveConfirm = () => {
        setConfirmModal(true);
    }

    const rowActions = ({ row }) => {
        return (
            <label key={row.original.id}>
                <input
                    type="checkbox"
                    checked={checkedIds.includes(row.original.id)}
                    onChange={() => handleCheckboxChange(row.original.id, event)}
                />
            </label>
        );
    };

    const rowActionHeader = () => {
        return (
            <label key={0}>
                <input
                    type="checkbox"
                    checked={masterChecked}
                    onChange={handleMasterCheckboxChange}
                />
            </label>
        );
    };

    useEffect(() => {
        if (notifications?.length === checkedIds?.length) {
            setMasterChecked(true);
        }
        else {
            setMasterChecked(false);
        }
    }, [checkedIds, notifications]);

    const handleCheckboxChange = (notificationId, event) => {
        if (event.target.checked) {
            if (!checkedIds.includes(notificationId)) {
                setCheckedIds((prevIds) => [...prevIds, notificationId]);
            }
        }
        else {
            if (checkedIds.includes(notificationId)) {
                setCheckedIds((prevIds) => prevIds.filter((prevId) => prevId !== notificationId));
            }
        }
    }

    const handleMasterCheckboxChange = (event) => {
        if (event.target.checked) {
            const notificationIds = notifications.map((notification) => notification.id);
            setCheckedIds(notificationIds);
            setMasterChecked(true);
        }
        else {
            setCheckedIds([]);
            setMasterChecked(false);
        }
    }

    const columnsNotification = [
        {
            id: 'actions',
            header: rowActionHeader,
            cell: rowActions,
            size: 32,
            minSize: 32,
        },
        {
            id: 'dateTime',
            header: 'Date/Time',
            accessorKey: 'dateTime',
            sortType: 'date',
            accessorFn: (row) => (new Date(row.dateTime)),
            cell: ({ row }) => `${toDateTime(row.original.dateTime)}`,
            sortingFn: 'datetime',
            filterFn: dateObjectEquals,
        },
        {
            id: 'type',
            header: 'Type',
            sortType: 'text',
            accessorKey: 'type',
            accessorFn: (row) => `${NOIFICATION_TYPE[row.type]}`,
        },
        {
            id: 'application',
            header: 'Application',
            sortType: 'text',
            accessorKey: 'application',
            accessorFn: (row) => `${NOIFICATION_APPLICATION[row.application]}`,
        },
        {
            id: 'source',
            header: 'Source',
            sortType: 'text',
            accessorKey: 'source',
        },
        {
            id: 'cohortId',
            header: 'Cohort',
            accessorFn: (originalRow) => originalRow.cohortId.toString(),
            filterFn: 'equalsString',
            accessorKey: 'cohortId',
        },
        {
            id: 'memberId',
            header: 'Member',
            accessorKey: 'memberId',
            enableSorting: false
        },
        {
            id: 'message',
            header: 'Message Text',
            accessorKey: 'message',
            cell: ({ row, cell }) => <OverflowText id={cell.id}>{row.original.message}</OverflowText>,
            enableSorting: false
        },
    ];

    return (
        <div className="h-100 d-flex flex-column">
            <ActionBar>
                <Button type="button" className="me-2" variant="primary" disabled={checkedIds.length === 0}
                    onClick={handleArchiveConfirm}>
                    Archive Selected
                </Button>
            </ActionBar>

            <div className="flex-grow-1">
                {notifications === null ? (
                    <Loader />
                ) : (
                    <DataGrid
                        columns={columnsNotification}
                        data={notifications}
                        sort={defaultSort}
                    />
                )}
            </div>

            <ConfirmModal
                show={confirmModal}
                message={messages.confirm.archiveNotifications}
                onConfirm={handleConfirm}
                onCancel={handleConfirmClose}
                onHideCallback={handleConfirmClose}
            />
        </div>
    );
};

export default withAuth(SystemNotification);
