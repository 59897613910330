import api from '../../services/api/nonMemberService';
import keyMirror from '../../utils/keyMirror';

export const actionTypes = keyMirror(
    {
        GET_ALL_NON_MEMBERS: null,
        ADD_NON_MEMBER: null,
        UPDATE_NON_MEMBER: null,
        GET_MEETING_ATTENDANCE: null,
        ADD_NON_MEMBER_ATTENDANCE: null,
        UPDATE_NON_MEMBER_ATTENDANCE: null,
    },
    "NonMember"
);

// Action Creators
export const getNonMembersByAgId = (agId) => dispatch => {
    api.GetNonMembersByAgId(agId).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ALL_NON_MEMBERS,
            payload: data,
        });
    })
}

export const addNonMember = (data, successHandler, errorHandler) => dispatch => {
    api.AddNonMember(data)
        .then(() => {
            dispatch({
                type: actionTypes.ADD_NON_MEMBER,
                payload: data,
            });
            successHandler();
        })
        .catch(error => {
            errorHandler(error);
        });
}

export const updateNonMember = (data, successHandler, errorHandler) => dispatch => {
    api.UpdateNonMember(data)
        .then(() => {
            dispatch({
                type: actionTypes.UPDATE_NON_MEMBER,
                payload: data,
            });
            successHandler();
        })
        .catch(error => {
            errorHandler(error);
        });
}

export const getNonMemberAttendanceByMeetingId = (agId, meetingId) => dispatch => {
    api.GetNonMemberAttendanceByMeetingId(agId, meetingId).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_MEETING_ATTENDANCE,
            payload: data,
        });
    })
}

export const addNonMemberAttendance = (data, successHandler, errorHandler) => dispatch => {
    api.AddNonMemberAttendance(data)
        .then(() => {
            dispatch({
                type: actionTypes.ADD_NON_MEMBER_ATTENDANCE,
                payload: data,
            });
            successHandler();
        })
        .catch(error => {
            errorHandler(error);
        });
}

export const updateNonMemberAttendance = (data, successHandler, errorHandler) => dispatch => {
    api.UpdateNonMemberAttendance(data)
        .then(() => {
            dispatch({
                type: actionTypes.UPDATE_NON_MEMBER_ATTENDANCE,
                payload: data,
            });
            successHandler();
        })
        .catch(error => {
            errorHandler(error);
        });
}

// Reducer
export const initialState = {
    memberList: null,
    meetingAttendance: null,
}


export default function nonMembersReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actionTypes.GET_ALL_NON_MEMBERS:
            return {
                ...state,
                memberList: payload,
            };
        case actionTypes.GET_MEETING_ATTENDANCE:
            return {
                ...state,
                meetingAttendance: payload,
            };
        default:
            return state;
    }
}
