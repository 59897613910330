import { apiInstance } from "../apiInstance";
import { protectedResources } from "../../msalAuthConfig";
import { convertToAdvisoryGroupMasterModelDataObject } from "../../utils/helper/DTO";

const headers = {
    'Content-Type': 'application/json',
};

const ProgramApi = {
    getProgramData: (companyId) => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/${companyId}/advisory-group-master`,
            headers,
        })
    },

    getProgramDataByStatus: (companyId, status) => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/${companyId}/advisory-group-master/${status}`,
            headers,
        })
    },

    getProgramDataById: (id) => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/${id}`,
            headers,
        })
    },

    archiveProgram: (programData) => {
        return apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/master/${programData.id}/status`,
            headers,
            data: programData
        })
    },

    createProgram: async (programData) => {
        return apiInstance({
            method: 'POST',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/`,
            headers,
            data: convertToAdvisoryGroupMasterModelDataObject(programData)
        })
    },

    updateProgram: async (programData) => {
        return apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/${programData.id}`,
            headers,
            data: convertToAdvisoryGroupMasterModelDataObject(programData, programData.id)
        })
    },

    copyProgram: async (programData) => {
        return apiInstance({
            method: 'POST',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/`,
            headers,
            data: convertToAdvisoryGroupMasterModelDataObject(programData)
        })
    },

    GetMeetingMembersData: async (id, aGId) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/${id}/meeting/${aGId}/member`,
            headers,
        });
    },

    GetAgMemberMeetingDetails: async (agMemberId) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/attendance-log/member/${agMemberId}`,
            headers,
        });
    },

    UpdateAdvisoryGroupRosterMemberData: async (data) => {
        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/member/${data.id}/roster`,
            headers,
            data
        });
    },
};

export default ProgramApi;
