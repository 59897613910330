import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionBar from '../../../../components/ui/ActionBar';
import Loader from '../../../../components/ui/Loader';
import DataGrid from '../../../../components/DataGrid';
import { AppLoadContext } from '../../../../components/ui/AppLoadContext';
import { getNonMembersByAgId } from '../../../../ducks/nonMembers';
import Icon from '../../../../components/ui/Icon';
import GridButton from '../../../../components/ui/GridButton';
import AddNonMemberRepModal from './AddNonMemberRepModal';
import { FORM_ACTION_TYPE } from '../../../../constants';
import { calculateAttendancePercentage, pickProps } from '../../../../utils/utils';

const AgOpsNonMembers = ({ agId, currentUser }) => {
    const dispatch = useDispatch();
    const isAppLoaded = useContext(AppLoadContext);

    const memberList = useSelector(state => state.nonMembers.memberList);

    const [editModal, setEditModal] = useState(false);
    const [nonMemberDetails, setNonMemberDetails] = useState(false);
    const [selectedNonMemberId, setSelectedNonMemberId] = useState(null);

    const columns = [
        {
            id: 'firstName',
            header: 'First Name',
            accessorKey: 'firstName',
            sortingFn: 'text'
        },
        {
            id: 'lastName',
            header: 'Last Name',
            accessorKey: 'lastName',
        },
        {
            id: 'meetingDate',
            header: 'Last Meeting Attended',
            accessorKey: 'meetingDate',
        },
        {
            id: 'attendance',
            header: 'Attendance %',
            accessorKey: 'attendance',
            cell: ({ row }) => `${calculateAttendancePercentage(row.original.attendedAttendance, row.original.totalAttendance)}%`,
        },
        {
            name: 'actions',
            header: 'Actions',
            size: 60,
            cell: ({ row }) => (
                <GridButton variant="light" size="sm" className="me-1 my-1" title="Edit"
                            onClick={() => handleEditModalOpen(row.original)}>
                    <Icon icon="edit" size={14} />
                </GridButton>
            )
        },
    ];

    const handleEditModalOpen = (data) => {
        const updateData = pickProps(data, ['firstName', 'lastName', 'email', 'phone', 'status', 'startDate', 'communicationLastDate', 'onboarding', 'docCode', 'retireDate', 'retirementReason']);
        setSelectedNonMemberId(data.id);
        setNonMemberDetails(updateData);
        setEditModal(true);
    }

    const handleEditModalClose = () => {
        setSelectedNonMemberId(null);
        setNonMemberDetails(null);
        setEditModal(false);
    }

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getNonMembersByAgId(agId))
        }
    }, [dispatch, isAppLoaded, currentUser]);

    return (
        <>
            <ActionBar title="Non-Member Roster" />
            {memberList === null ? (
                <Loader />
            ) : (
                <DataGrid
                    data={memberList}
                    columns={columns}
                    enableFilters={false}
                />
            )}

            {editModal && (
                <AddNonMemberRepModal
                    show={editModal}
                    action={FORM_ACTION_TYPE.Edit}
                    info={{
                        id: selectedNonMemberId,
                        agId: agId,
                    }}
                    data={nonMemberDetails}
                    onCancel={handleEditModalClose}
                />
            )}
        </>
    )
}

export default AgOpsNonMembers;
