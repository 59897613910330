import { useEffect, useContext, useState } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import withAuth from "../../../components/hoc/withAuth";
import DataGrid from "../../../components/DataGrid";
import { toDate, isBeforeToday, sortingFnDateOnly, sortingFnText } from "../../../utils/utils";
import { AppLoadContext } from '../../../components/ui/AppLoadContext';
import Loader from "../../../components/ui/Loader";
import api from "../../../services/api/advisoryGroupService";
import GridButton from "../../../components/ui/GridButton";
import Icon from "../../../components/ui/Icon";
import { PROGRAM_MEETING_TYPE, MEETING_STATUS, AGGREGATE_SCORES, AGGREGATE_SCORES_COLOR } from "../../../constants";
import OverflowText from "../../../components/ui/OverflowTooltip";
import OperstionsStatusModal from "./OperstionsStatusModal";
import ActionBar from "../../../components/ui/ActionBar";
import { Button } from "react-bootstrap";
import NewMeetingModal from "./operation-details/NewMeetingModal";

const Operations = () => {
    const isAppLoaded = useContext(AppLoadContext);
    const navigate = useNavigate();
    const currentUser = useSelector((state) => state.user.currentUser);
    const [operationsData, setOperationsData] = useState(null);
    const [futureMeetingData, setFutureMeetingData] = useState(null);
    const [pastMeetingData, setPastMeetingData] = useState(null);
    const [currentModaldata, setCurrentModaldata] = useState(null);
    const [showOperationstatusModal, setShowOperationstatusModal] = useState(false);
    const [showMeetingModal, setShowMeetingModal] = useState(false);

    const hideColumn = {
        id: false,
    };

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            generateData(currentUser?.companyId);
        }
    }, [isAppLoaded, showMeetingModal]);

    const generateData = (id) => {
        api.GetAdvisoryGroupOperationsData(id).then((res) => {
            setOperationsData(res.data)
        });
        api.GetAdvisoryGroupFutureMeetingData(id).then((res) => {
            setFutureMeetingData(res.data)
        });
        api.GetAdvisoryGroupPastMeetingData(id).then((res) => {
            setPastMeetingData(res.data)
        });
    }

    const OperationsColumn = [
        {
            id: 'shortName',
            header: 'AG Name',
            accessorKey: 'shortName',
            sortingFn: 'text'
        },
        {
            id: 'market',
            header: 'Market',
            accessorKey: 'market',
        },
        {
            id: 'memberCount',
            header: '# of Members',
            accessorKey: 'memberCount',
        },
        {
            id: 'NextMeetingDate',
            header: 'Next Meeting Date',
            accessorKey: 'nextMeetingTime',
            sortType: 'date',
            accessorFn: (row) => (new Date(row.nextMeetingTime)),
            cell: ({ row }) => `${toDate(row.original.nextMeetingTime)}`,
            sortingFn: 'datetime'
        },
        {
            id: 'LastMeetingDate',
            header: 'Last Meeting Date',
            accessorKey: 'lastMeetingTime',
            accessorFn: (row) => `${toDate(row.lastMeetingTime)}`,
            sortingFn: 'datetime'
        },
        {
            id: 'selectionGrade',
            header: 'Selection Grade',
            accessorKey: 'selectionGrade',
            accessorFn: (originalRow) => AGGREGATE_SCORES[originalRow.selectionGrade],
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    <div className={`px-2 ${AGGREGATE_SCORES_COLOR[row.original.selectionGrade]}`}>
                        {AGGREGATE_SCORES[row.original.selectionGrade]}
                    </div>
                </OverflowText>
            ),
        },
        {
            id: 'currentParticipationGrade',
            header: 'Current Participation Grade',
            accessorKey: 'currentParticipationGrade',
        },
        {
            name: 'actions',
            header: 'Actions',
            size: 90,
            cell: ({ row }) => (
                <>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title={"View"}
                        onClick={() => navigate(`/advisory-group-management/operations/view/${row.original.id}`)}>
                        <Icon icon={"view"} size={14} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Analyze">
                        <Icon icon="analyze" size={14} />
                    </GridButton>
                </>
            )
        },
    ];

    const pastMeetingColumn = [
        {
            id: 'id',
            header: 'ID',
            accessorKey: 'id',
            cell: ({ row }) => row.original.id,
        },
        {
            id: 'pastMeetingTime',
            header: 'Meeting Date',
            accessorKey: 'pastMeetingTime',
            sortType: 'date',
            accessorFn: (row) => (new Date(row.pastMeetingTime)),
            cell: '',
            aggregatedCell: ({ row }) => `${toDate(row.original.pastMeetingTime)}`,
            sortingFn: sortingFnDateOnly,
            size: 150,
        },
        {
            id: 'shortName',
            header: 'AG Name',
            accessorKey: 'shortName',
            cell: () => '',
            aggregatedCell: ({ row }) => row.original.shortName,
            sortingFn: sortingFnText,
        },
        {
            id: 'meetingLocation',
            header: 'Location',
            sortType: 'text',
            accessorKey: 'meetingLocation',
            sortingFn: sortingFnText
        },
        {
            id: 'preMeetingResponse',
            header: 'Pre-meeting Response',
            columns: [
                {
                    id: 'confirmedCount',
                    header: 'Confirmed',
                    accessorKey: 'confirmedCount',
                    enableSorting: false,
                    cell: ({ row }) => row.original.confirmedCount ?? 0,
                    aggregatedCell: () => null,
                },
                {
                    id: 'noResponseCount',
                    header: 'No Response',
                    accessorKey: 'noResponseCount',
                    enableSorting: false,
                    cell: ({ row }) => row.original.noResponseCount ?? 0,
                    aggregatedCell: () => null,
                },
                {
                    id: 'declinedCount',
                    header: 'Declined',
                    accessorKey: 'declinedCount',
                    enableSorting: false,
                    cell: ({ row }) => row.original.declinedCount ?? 0,
                    aggregatedCell: () => null,
                },
                {
                    id: 'maybeCount',
                    header: 'Maybe',
                    accessorKey: 'maybeCount',
                    enableSorting: false,
                    cell: ({ row }) => row.original.maybeCount ?? 0,
                    aggregatedCell: () => null,
                }
            ],
        },
        {
            id: 'meetingParticipation',
            header: 'Meeting Participation',
            columns: [
                {
                    id: 'attendedCount',
                    header: 'Attended',
                    accessorKey: 'attendedCount',
                    enableSorting: false,
                    cell: ({ row }) => row.original.attendedCount ?? 0,
                    aggregatedCell: () => null,
                },
                {
                    id: 'canceledCount',
                    header: 'Cancelled',
                    accessorKey: 'canceledCount',
                    enableSorting: false,
                    cell: ({ row }) => row.original.canceledCount ?? 0,
                    aggregatedCell: () => null,
                },
                {
                    id: 'noShowCount',
                    header: 'No Show',
                    accessorKey: 'noShowCount',
                    enableSorting: false,
                    cell: ({ row }) => row.original.noShowCount ?? 0,
                    aggregatedCell: () => null,
                },
                {
                    id: 'unplannedCount',
                    header: 'Unplanned',
                    accessorKey: 'unplannedCount',
                    enableSorting: false,
                    cell: ({ row }) => row.original.unplannedCount ?? 0,
                    aggregatedCell: () => null,
                },
            ],
        },
        {
            name: 'actions',
            header: 'Actions',
            size: 90,
            aggregatedCell: ({ row }) => (
                <>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title={"View"}
                        onClick={() => navigate(`/advisory-group-management/operations/meeting-details/${row.original.id}/${row.original.aGId}`)}>
                        <Icon icon={"view"} size={14} />
                    </GridButton>
                </>
            )
        },
    ];

    const futureMeetingColumn = [
        {
            id: 'nextMeetingTime',
            header: 'Next Mtg Date',
            accessorKey: 'nextMeetingTime',
            sortType: 'date',
            accessorFn: (row) => (new Date(row.nextMeetingTime)),
            cell: ({ row }) => `${toDate(row.original.nextMeetingTime)}`,
            sortingFn: 'datetime',
            size: 150,
        },
        {
            id: 'shortName',
            header: 'AG Name',
            accessorKey: 'shortName',
            sortingFn: 'text'
        },
        {
            id: 'meetingLocation',
            header: 'Location',
            sortType: 'text',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.meetingLocation?.split(',').length > 1 ? 'Multiple' : row.original.meetingLocation}
                </OverflowText>
            ),
            accessorKey: 'meetingLocation'
        },
        {
            id: 'status',
            header: 'Status',
            accessorKey: 'status',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id} label={MEETING_STATUS[row.original.status]}>
                    <div className={`px-2${['P', 'S'].includes(row.original.status) && isBeforeToday(row.original.nextMeetingTime) ? ' bg-red' : ''}`}>
                        {MEETING_STATUS[row.original.status]}
                    </div>
                </OverflowText>
            ),
        },
        {
            id: 'type',
            header: 'Type',
            accessorKey: 'type',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {PROGRAM_MEETING_TYPE[row.original.type]}
                </OverflowText>
            ),
        },
        {
            id: 'preMeetingResponse',
            header: 'Pre-meeting Response',
            columns: [
                {
                    id: 'confirmedCount',
                    header: 'Confirmed',
                    accessorKey: 'confirmedCount',
                    enableSorting: false,
                },
                {
                    id: 'noResponseCount',
                    header: 'No Response',
                    accessorKey: 'noResponseCount',
                    enableSorting: false,
                },
                {
                    id: 'declinedCount',
                    header: 'Declined',
                    accessorKey: 'declinedCount',
                    enableSorting: false,
                },
                {
                    id: 'maybeCount',
                    header: 'Maybe',
                    accessorKey: 'maybeCount',
                    enableSorting: false,
                }
            ]
        },
        {
            name: 'actions',
            header: 'Actions',
            size: 90,
            cell: ({ row }) => (
                <>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title={"View"}
                        onClick={() => navigate(`/advisory-group-management/operations/meeting-details/${row.original.id}/${row.original.aGId}`)}>
                        <Icon icon={"view"} size={14} />
                    </GridButton>
                </>
            )
        },
    ];

    const handleConfirmClose = () => {
        setCurrentModaldata(null);
        setShowOperationstatusModal(false);
        setShowMeetingModal(false);
    };

    const defaultSortFutureMeets = [{
        id: 'nextMeetingTime',
        desc: true,
    }];

    const defaultSortPastMeets = [{
        id: 'pastMeetingTime',
        desc: true,
    }];

    const defaultSortOperation = [{
        id: 'shortName',
        desc: false
    }];

    return (
        <div className="flex-grow-1 mb-4">
            <div className="mb-4 pb-2">
                <ActionBar title="AG Operations Status" />
                {operationsData === null ? <Loader /> :
                    <DataGrid
                        columns={OperationsColumn}
                        data={operationsData}
                        enableFilters={false}
                        sort={defaultSortOperation}
                    />
                }
            </div>
            <div className="mb-4 pb-2">
                <ActionBar
                    className=" d-flex gap-2"
                    title="Future Meeting Schedule">
                    <div>
                        <Button
                            className="me-2"
                            type="button"
                            variant="primary"
                            onClick={() => setShowMeetingModal(true)}>
                            New Meeting
                        </Button>
                        <Button
                            type="button"
                            variant="primary"
                            onClick={() => {navigate('/advisory-group-management/operations/master-meeting-schedule')}}>
                            Master Schedule
                        </Button>
                    </div>
                </ActionBar>
                {futureMeetingData === null ? <Loader /> :
                    <DataGrid
                        columns={futureMeetingColumn}
                        data={futureMeetingData}
                        enableFilters={false}
                        sort={defaultSortFutureMeets}
                    />
                }
            </div>
            <div className="mb-4 pb-2">
                <ActionBar title="Past Meeting Result Summary" />
                {pastMeetingData === null ? <Loader /> :
                    <DataGrid
                        columns={pastMeetingColumn}
                        data={pastMeetingData}
                        enableFilters={false}
                        sort={defaultSortPastMeets}
                        groupBy={['id']}
                        columnDisplay={hideColumn}
                    />
                }
            </div>

            <OperstionsStatusModal
                show={showOperationstatusModal}
                onCancel={handleConfirmClose}
                data={currentModaldata}
            />

            <NewMeetingModal
                show={showMeetingModal}
                onCancel={handleConfirmClose}
                agSelector={true}
            />
        </div>
    );
}

export default withAuth(Operations);
