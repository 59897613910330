import { models } from 'powerbi-client';

export const USER_ROLE = {
    SYSTEM_ADMIN: 1,
    CLIENT_ADMIN: 2,
    USER: 3,
};

export const LOCAL_STORAGE_KEY = {
    CURRENT_USER: 'CURRENT_USER',
};

export const reportLayoutSettings = {
    layoutType: models.LayoutType.Master,
    customLayout: {
        pageSize: {
            type: models.PageSizeType.Custom,
        },
        displayOption: models.DisplayOption.FitToPage,
        pagesLayout: {
            ReportSection1: {
                defaultLayout: {
                    displayState: {
                        mode: models.VisualContainerDisplayMode.Hidden,
                    },
                },
                visualsLayout: {
                    VisualContainer1: {
                        x: 1,
                        y: 1,
                        z: 1,
                        displayState: {
                            mode: models.VisualContainerDisplayMode.Visible,
                        },
                    },
                    VisualContainer2: {
                        displayState: {
                            mode: models.VisualContainerDisplayMode.Visible,
                        },
                    },
                },
            },
        },
    },
};

export const POWER_BI_REPORTS = {
    STAR_REPORT_CARD: 'Star Report Card',
    STARS_DATA_EXPLORER: 'Stars Data Explorer',
    POPULATION_CHARECTERIZATIONS: 'Population Characterizations'
};

export const USER_STATUS = {
    O: 'Operational',
    P: 'Permanent',
    A: 'Archived',
}

export const USER_STATUS_VALUE = {
    Operational: 'O',
    Permanent: 'P',
    Archived: 'A',
}

export const DEFAULT_PAGE = "Home";

export const COHORT_FILE_STATUS = {
    UPLOADED: 'Uploaded',
    VALIDATED: 'Validated',
    ENRICHED: 'Enriched',
    SCHEDULE: 'Scheduled',
    ERROR: 'Error',
    ARCHIVED: 'Archived',
};

export const RETENTION_MANAGEMENT_MEMBER_STATUS = {
    VALIDATED: 'V',
    VALIDATION_ERROR: 'VE',
    MATCHED: 'M',
    MATCHED_ERROR: 'ME',
    PROCESSED: 'P',
    REMOVED: 'R',
    EXCLUDED: 'X',
};

export const RETENTION_MANAGEMENT_MEMBER_STATUS_VALUE = {
    V: 'Validated',
    VE: 'Validation Error',
    M: 'Matched',
    ME: 'Matched Error',
    P: 'Processed',
    R: 'Removed',
    X: 'Excluded',
    NC: 'No Contact',
}

export const COHORT_TRAINING_FLAG = {
    Y: 'Yes',
    N: 'No',
};

export const COHORT_MARKET_TYPE = {
    Single: 'Single',
    Multiple: 'Multiple',
};

export const AUDIENCE_USER_ROLE = {
    A: 'All', // default
    U: 'User',
    Z: 'Admin',
}

export const AUDIENCE_APPLICATION = {
    A: 'All', // default
    G: 'Advisory Group',
    M: 'Member Analytics',
    Q: 'Quality Management',
    Z: 'Admin Center',
}

export const CONTENT_APPLICATION = {
    ALL: 'A',
    ADVISORY_GROUP: 'G',
    MEMBER_ANALYTICS: 'M',
    QUALITY_MANAGEMENT: 'Q',
    ADMIN: 'Z',
}

export const AUDIENCE_APPLICATION_STATUS = {
    ADVISORY_GROUP: 'G',
    MEMBER_ANALYTICS: 'M',
    QUALITY_MANAGEMENT: 'Q',
    ADMIN: 'A',
    SYSTEM: 'S'
}

export const AUDIENCE_SEGMENT = {
    A: 'All', // default
    H: 'Provider',
    P: 'Plan',
}

export const CMS_CONTENT_TYPE = {
    B: 'Blog',
    N: 'News',
    G: 'Graph of Month',
    A: 'Product Announcement',
    T: 'Product Tip',
}

export const CONTENT_TYPE = {
    Blog: 'B',
    News: 'N',
    GraphOfMonth: 'G',
    ProductAnnouncement: 'A',
    ProductTip: 'T'
}

export const CMS_CONTENT_FORMAT = {
    P: 'PDF',
    J: 'JPEG',
    H: 'HTML',
}

export const MIME_TYPES = {
    JPG: 'image/jpeg',
    JPEG: 'image/jpeg',
    JFIF: 'image/jpeg',
    PNG: 'image/png',
    SVG: 'image/svg+xml',
    GIF: 'image/gif',
    PDF: 'application/pdf',
    HTML: 'text/html',
    XLS: 'application/vnd.ms-excel',
    CSV: 'text/csv',
}

export const RECORD_STATUS = {
    O: 'Operational',
    A: 'Archived'
}

export const RECORD_STATUS_ABBR = {
    Operational: 'O',
    Archived: 'A'
}


export const CONTENT_RECORD_STATUS = {
    Operational: 'O',
    Archived: 'A',
    Permanent: 'P'
}

export const NOTIFICATION_RECORD_STATUS = {
    OPEN: 'O',
    ARCHIVED: 'A'
}

export const POSITIONS = {
    TOP: 'top',
    BOTTOM: 'bottom',
}

export const ALIGN = {
    LEFT: 'start',
    RIGHT: 'end',
}

export const PROGRAM_TYPE = {
    O: 'Other',
    E: 'EAC',
}

export const PROGRAM_TYPE_ABBR = {
    Other: 'O',
    EAC: 'E',
}

export const AGGREGATE_SCORES = {
    N: 'Non Compliant',
    M: 'Minimally Compliant',
    P: 'Partially Compliant',
    C: 'Compliant'
}

export const AGGREGATE_SCORES_COLOR = {
    N: 'bg-red',
    M: 'bg-yellow',
    P: 'bg-yellow',
    C: 'bg-green'
}

export const PROGRAM_STATUS = {
    New: 'New',
    Active: 'Active',
    Error: 'Error',
    Archived: 'Archived',
}

export const AG_STATUS = {
    New: 'New',
    Active: 'Active',
    Error: 'Error',
    Archived: 'Archived',
}

export const US_STATE = {
    '': '', AL: 'AL', AK: 'AK', AZ: 'AZ', AR: 'AR', CA: 'CA', CO: 'CO', CT: 'CT', DE: 'DE',
    FL: 'FL', GA: 'GA', HI: 'HI', ID: 'ID', IL: 'IL', IN: 'IN', IA: 'IA', KS: 'KS', KY: 'KY',
    LA: 'LA', ME: 'ME', MD: 'MD', MA: 'MA', MI: 'MI', MN: 'MN', MS: 'MS', MO: 'MO', MT: 'MT',
    NE: 'NE', NV: 'NV', NH: 'NH', NJ: 'NJ', NM: 'NM', NY: 'NY', NC: 'NC', ND: 'ND', OH: 'OH',
    OK: 'OK', OR: 'OR', PA: 'PA', RI: 'RI', SC: 'SC', SD: 'SD', TN: 'TN', TX: 'TX', UT: 'UT',
    VT: 'VT', VA: 'VA', WA: 'WA', WV: 'WV', WI: 'WI', WY: 'WY'
}

export const PROGRAM_MEETING_FREQUENCY = {
    M: 'Monthly',
    Q: 'Quarterly',
    B: 'Bimonthly',
    O: 'Other'
}

export const PROGRAM_MEETING_TYPE = {
    I: 'In person',
    H: 'Hybrid',
    V: 'Virtual'
}

export const AG_RECRUITMENT_MEMBER_STATUS = {
    Applied: 'Applied',
    Interest: 'Interest',
    Selected: 'Selected',
    Hold: 'Hold',
    Enrolled: 'Enrolled',
    Decline: 'Decline',
    Retired: 'Retired',
    Expired: 'Expired',
    Error: 'Error',
    Augmented: 'Augmented',
    Archive: 'Archive'
};

export const SUPPORT_STAFF_MEMBER_STATUS = {
    Operational: 'O',
    Archived: 'A'
}

export const AG_RECRUITMENT_APPLICATION_STATUS_VALUE = {
    Open: 'Open',
    Matched: 'Matched',
    Invalid: 'Invalid',
    Error: 'Error',
    Archive: 'Archive',
    New: 'New'
}

export const AG_RECRUITMENT_COMMUNICATION_TYPE = {
    I: 'Invitation',
    H: 'Hold Letter',
    D: 'Decline Letter',
    A: 'Acceptance',
    O: 'Other',
}

export const AG_RECRUITMENT_RETIREMENT_REASON = {
    T: 'Term Ended',
    R: 'Resigned',
    A: 'Abandoned',
    S: 'Sanctioned',
}

export const AG_NON_MEMBER_STATUS = {
    Enrolled: 'Enrolled',
    Retired: 'Retired',
    Error: 'Error',
}

export const AG_NON_MEMBER_RETIREMENT_REASON = {
    T: 'Term Ended',
    R: 'Resigned',
    A: 'Abandoned',
    S: 'Sanctioned',
    U: 'Unenrolled',
}

export const MEETING_STATUS = {
    P: 'Planned',
    S: 'Scheduled',
    C: 'Completed'
}

export const AG_MEETING_STATUS = {
    P: 'Planned',
    S: 'Scheduled',
    C: 'Completed',
    D: 'Documented',
    A: 'Archive',
    X: 'Cancelled'
}

export const AG_REPSCORE_TYPE = {
    Panel: 'P',
    Baseline: 'B',
    Attendance: 'A'
}

export const AG_REPSCORE_TYPE_VALUE = {
    P: 'Panel Score',
    B: 'Baseline',
    A: 'Attendance Score'
}

export const AG_REPSCORE_COLUMN_HEADER_PREFIX = {
    P: 'Panel',
    A: 'Attendance'
}

export const AG_MEETING_STATUS_INVERSE = {
    Planned: 'P',
    Scheduled: 'S',
    Completed: 'C',
    Documented: 'D',
    Archive: 'A',
    Cancelled: 'X'
};


export const AG_MEETING_ATTENDANCE_STATUS_PRE = {
    N: 'No Response',
    C: 'Confirmed',
    D: 'Declined',
    M: 'Maybe'
}

export const AG_MEETING_ATTENDANCE_STATUS_PRE_INVERSE = {
    NoResponse: 'N',
    Confirmed: 'C',
    Declined: 'D',
    Maybe: 'M'
}

export const AG_MEETING_ATTENDANCE_STATUS_POST = {
    NA: 'Future Meeting',
    X: 'Cancelled',
    A: 'Attended',
    M: 'No show',
    D: 'Declined / No Response',
    U: 'Unplanned'
}

export const AG_MEETING_ATTENDANCE_STATUS_POST_INVERSE = {
    FutureMeeting: 'NA',
    Cancelled: 'X',
    Attended: 'A',
    NoShow: 'M',
    Declined: 'D',
    Unplanned: 'U'
}

export const AG_MEETING_NON_MEMBER_ATTENDANCE_STATUS_PRE = {
    Confirmed: 'C',
    Cancelled: 'X',
}

export const AG_MEETING_NON_MEMBER_ATTENDANCE_STATUS_PRE_VALUE = {
    C: 'Confirmed',
    X: 'Cancelled',
}

export const AG_MEETING_NON_MEMBER_ATTENDANCE_STATUS_POST = {
    Cancelled: 'X',
    Attended: 'A',
    NoShow: 'N',
    NA: 'NA',
}

export const AG_MEETING_NON_MEMBER_ATTENDANCE_STATUS_POST_VALUE = {
    X: 'Cancelled',
    A: 'Attended',
    N: 'No show',
    NA: 'NA',
}

export const AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS = {
    Assigned: 'A',
    Confirmed: 'C',
    Cancelled: 'X',
}

export const AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_VALUE = {
    A: 'Assigned',
    C: 'Confirmed',
    X: 'Cancelled',
}

export const AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST = {
    NA: 'NA',
    Cancelled: 'X',
    Attended: 'A',
    NoShow: 'NS',
}

export const AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST_VALUE = {
    NA: 'NA',
    X: 'Cancelled',
    A: 'Attended',
    NS: 'NoShow',
}

export const AG_MEETING_SUPPORT_STAFF_RECORD_STATUS = {
    Operational: 'O',
    Cancelled: 'X',
}

export const YES_NO = {
    Y: 'Yes',
    N: 'No'
}

export const YES_NO_INVERSE = {
    Yes: 'Y',
    No: 'N'
}

export const FORM_ACTION_TYPE = {
    New: 'New',
    Edit: 'Edit',
    View: 'View',
    Copy: 'Copy'
}

export const NOIFICATION_TYPE = {
    A: 'Alert',
    E: 'Error',
    P: 'Process',
    M: 'Informational Management',
    S: 'System'
}

export const NOIFICATION_APPLICATION = {
    A: 'Admin',
    M: 'Member Analytics',
    G: 'Advisory Group Management',
    Q: 'Quality Management',
    S: 'System'
}

export const EXPORT_RETENTION_RISK_FORMAT = {
    P: 'pdf',
    X: 'xls',
    C: 'csv',
}

export const COHORT_RETENTION_RISK_STATUS = {
    Requested: 'R',
    Scored: 'S',
    Error: 'E',
    Empty: ''
}

export const COHORT_RETENTION_RISK_STATUS_VALUE = {
    R: 'Requested',
    S: 'Scored',
    E: 'Error',
}

export const SUBMIT_ACTION = {
    SUCCESS: 'success',
    ERROR: 'error',
};

export const ALERT_TYPE = {
    SUCCESS: 'success',
    ERROR: 'error',
};

export const MESSAGE_LISTEN_TYPE = {
    SCHEDULE_COHORT: 'SCHEDULE_COHORT',
    PROCESSED_COHORT_FILE: 'PROCESSED_COHORT_FILE',
}

export const PARKING_STATUS = {
    F: 'Free',
    M: 'Master',
    V: 'Validated',
    U: 'Unknown'
}

export const COHORT_STATUS = {
    UPLOADED: 'Uploaded',
    VALIDATED: 'Validated',
    ENRICHED: 'Enriched',
    SCHEDULED: 'Scheduled',
    ERROR: 'Error',
    ARCHIVED: 'Archived',
};

export const RATINGS = {
    FC: 'Fully Compliant',
    CC: 'Compliant',
    SC: 'Somewhat Compliant',
    MC: 'Minimally Compliant',
    NC: 'Not Compliant',
    XC: 'Significantly Not Compliant',
}

export const EAC_PHONE_TYPE = {
    Mobile: 'Mobile',
    Landline: 'Landline'
}

export const VALIDATIONS = {
    Number: {
        isInteger: {
            test: /(?<![.+])^-?\d+$(?!.)/,
            msg: 'Value must be an integer'
        },
        isPositiveInteger: {
            test: /^[1-9]\d*$/,
            msg: 'Value must be a positive non-zero integer'
        },
        isId: {
            test: /^-?[1-9]\d*$/,
            msg: 'Value must be a non-zero integer'
        },
        isZipCode: { // US zipcode
            test: /^\d{5}(?:\d{4})?$/,
            msg: 'Zip code must be either 5 or 9 digits with non-zero first digit'
        },
        isPhone: { // US phone number
            // Regex for phone number with hyphen '-', brackets '()' or dot '.'
            // test: /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
            test: /^[1-9]\d{9}$/,
            msg: 'Phone number is invalid'
        }
    },
    String: {
        noSpecialChars: {
            test: /^(?!.*\)["'])[A-Za-z0-9 ?!,:;().'"_-\s]+$/,
            msg: 'Special characters not allowed. Allowed special chars: \? \! \, \: \; \( \) \. \' \" \_ \-'
        },
        isTitle: {
            test: /^[A-Za-z0-9 ,:._-]*$/,
            msg: 'Field should only have letters and allowed punctuation'
        },
        isName: {
            test: /^[A-Za-z]+( [A-Za-z]+)*$/,
            msg: 'Name should only have letters'
        },
        isURL: {
            test: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/[\w\-./?%&= ]*)?(?:#[\w]*)?$/,
            msg: 'Invalid URL. Please enter a valid URL.'
        }
    }
}

export const VALUE = {
    Min: 'min',
    Max: 'max',
}

export const NAVIGATION_BOX_TYPE = {
    LINK: 'Link',
    MODAL: 'Modal'
}

export const INITIAL_CALL_STATUS_VALUE = {
    A: 'Acceptance',
    N: 'Not Available',
    D: 'Declined',
    O: 'Other'
};

export const ONBOARDING_STATUS = {
    C: 'Complete',
    N: 'Not Started',
    I: 'In Progress'
};

export const INITIAL_CALL_STATUS = {
    Acceptance: 'A',
    Not_Available: 'N',
    Other: 'O',
    Declined: 'D'
};

export const ONBOARDING_STATUS_VALUE = {
    Complete: 'C',
    Not_Started: 'N',
    In_Progress: 'I'
};

export const EMPTY_OPTION = {
    '': 'Select'
}

export const MAX_VALUE = {
    INT: 2147483647,
}

export const AG_MEMBER_STATUS = {
    Temporary: 'Temp',
    New: 'New',
    Removed: 'Removed',
    MoveBackToPotential: 'MoveBackToPotential'
}

export const APPLICATION_SUBMISSION_TYPE = {
    PHARO_USER: 'P',
    EXTERNAL_USER: 'E'
}

export const REVISED_SCORE_TYPE = {
    PanelScore: 'P',
    BaseScore: 'B',
    AttendanceScore: 'A'
}

export const SUPPORT_STAFF_ROLE = {
    Client: 'Client',
    Community: 'Community',
    Consultant: 'Consultant'
};

export const CARE_GIVER_MEMBER_STATUS = {
    Operational: 'O',
    Archived: 'A'
}

export const TIMEZONES = {
    E: 'US/Eastern',
    C: 'US/Central',
    M: 'US/Mountain',
    P: 'US/Pacific'
}

export const TIMEZONE_ABBR = {
    Eastern: 'E',
    Central: 'C',
    Mountain: 'M',
    Pacific: 'P'
}

export const TRANSPORTATION_MODE = {
    D: 'Drive',
    A: 'Arranged',
    P: 'Public',
    O: 'Other'
}

export const AG_MEETING_ATTENDANCE_LINK_TYPE = {
    Member: 'M',
    NonMember: 'NM',
    SupportStaff: 'SS'
}
