import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row, Button } from 'react-bootstrap';
import withAuth from "../../../../components/hoc/withAuth";
import DataGrid from "../../../../components/DataGrid";
import ActionBar from "../../../../components/ui/ActionBar";
import GridButton from "../../../../components/ui/GridButton";
import Icon from "../../../../components/ui/Icon";
import { getCohorts } from "../../../../ducks/cohort";
import { useDispatch, useSelector } from "react-redux";
import { AppLoadContext } from "../../../../components/ui/AppLoadContext";
import Loader from "../../../../components/ui/Loader";
import AdvisoryGroupInfo from './AdvisoryGroupInfo';
import api from "../../../../services/api/advisoryGroupService";
import ProgramModal from "../../program-management/ProgramModal";
import {
    PROGRAM_TYPE,
    US_STATE,
    PROGRAM_MEETING_FREQUENCY,
    USER_STATUS_VALUE,
    PROGRAM_MEETING_TYPE,
    FORM_ACTION_TYPE,
    PROGRAM_STATUS,
    ALERT_TYPE,
    COHORT_FILE_STATUS,
    AG_MEETING_STATUS,
    AG_REPSCORE_TYPE_VALUE,
    AGGREGATE_SCORES,
    AGGREGATE_SCORES_COLOR,
    COHORT_STATUS,
    PROGRAM_TYPE_ABBR,
} from '../../../../constants';
import { calculateAttendancePercentage, optionValues, pickProps, toDate } from '../../../../utils/utils';
import { AlertContext } from "../../../../context/AlertContext";
import messages from "../../../../utils/helper/messages";
import { updateProgram } from "../../../../ducks/programManagement";
import OverflowText from "../../../../components/ui/OverflowTooltip";
import RepScoreModal from "./RepScoreModal";
import AttendanceLogDetailsModal from "./AttendanceLogDetailsModal";
import CommentModal from "./CommentModal";
import MemberDetailsModal from "./MemberDetailsModal";
import { getMemberComments, getAgMemberMeetingDetails, getRosterByAgId, getMeetingDetailsByAgId, getCaregiverDetailsByMemberId } from "../../../../ducks/advisoryGroup";
import NewMeetingModal from "./NewMeetingModal";
import CaregiverManagementModal from "./CaregiverManagementModal";
import { getCompanyUsersById } from "../../../../ducks/company";
import AddNonMemberRepModal from './AddNonMemberRepModal';
import AgOpsNonMembers from './AgOpsNonMembers';

const OperationDetails = () => {
    const { agId } = useParams();
    const isAppLoaded = useContext(AppLoadContext);
    const { showAlert } = useContext(AlertContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [recruitmentStatisticsData, setRecruitmentStatisticsData] = useState(null);
    const [repScoreData, setRepScoreData] = useState(null);
    const [documentData, setDocumentData] = useState(null);
    const [showAdvisoryGroupModal, setShowAdvisoryGroupModal] = useState(false);
    const [currentRowData, setCurrentRowData] = useState(null);
    const [cohortIdOptions, setCohortIdOptions] = useState(null);
    const [showRepScore, setShowRepScore] = useState(null);
    const [showMemberDetails, setShowMemberDetails] = useState(null);
    const [showAttendanceLog, setShowAttendanceLog] = useState(null);
    const [showComment, setShowComment] = useState(null);
    const [showMeetingModal, setShowMeetingModal] = useState(false);
    const [openCaregiverModal, setOpenCaregiverModal] = useState(false);
    const [projectManagerIdOptions, setProjectManagerIdOptions] = useState(null);
    // add non-member rep
    const [addNonMemberRepModal, setAddNonMemberRepModal] = useState(false);

    const currentUser = useSelector(state => state.user.currentUser);
    const cohortsData = useSelector((state) => state.cohort.cohorts);
    const rosterMemberDetails = useSelector(state => state.advisoryGroup?.rosterMemberDetails);
    const agMemberMeetingDetails = useSelector(state => state.advisoryGroup?.agMemberMeetingDetails);
    const companyUserData = useSelector((state) => state.company.users);
    const nonMembers = useSelector(state => state.nonMembers.memberList);

    const handleAdvisoryGroupModalOpen = () => {
        setShowAdvisoryGroupModal(true);
        const eacParams = ['eacPlanName', 'eacPlanContract', 'eacPlanPDBId'];
        const requiredParams = ['name', 'shortName', 'type', 'market', 'status', 'projectManagerId', 'cohortId', 'targetSize', 'meetingFrequency', 'meetingType'];
        const params = recruitmentStatisticsData[0].type === PROGRAM_TYPE_ABBR.EAC ? [...requiredParams, ...eacParams] : requiredParams;

        let data = pickProps(recruitmentStatisticsData[0], params);
        setCurrentRowData({
            ...data,
            cohortId: data.cohortId.toString(),
            targetSize: data.targetSize.toString(),
            projectManagerId: data.projectManagerId.toString(),
        });
    }

    const handleAddNonMemberRepModalOpen = () => {
        setAddNonMemberRepModal(true);
    }

    const handleAddNonMemberRepModalClose = () => {
        setAddNonMemberRepModal(false);
    }

    const handleRepScoreOpen = (data) => {
        setShowRepScore(true);
        setCurrentRowData({ ...data, aggregateScore: AGGREGATE_SCORES[data.aggregateScore], type: AG_REPSCORE_TYPE_VALUE[data.type] });
    }

    const handleAttendanceLogOpen = (data) => {
        setShowAttendanceLog(true);
        setCurrentRowData(data);
        dispatch(getAgMemberMeetingDetails(data.id));
    }

    const handleCommentOpen = (data) => {
        setShowComment(true);
        setCurrentRowData(data);
        dispatch(getMemberComments(data.id));
    }

    const handleCaregiverModalOpen = (data) => {
        setOpenCaregiverModal(true);
        setCurrentRowData(data);
        dispatch(getCaregiverDetailsByMemberId(data?.id));
    }

    const handleMemberDetailsOpen = (data) => {
        setShowMemberDetails(true);
        setCurrentRowData(data);
        dispatch(getMemberComments(data.id));
    }

    const handleAdvisoryGroupModalClose = () => {
        setShowAdvisoryGroupModal(false);
        setShowMemberDetails(false);
        setShowRepScore(false);
        setShowAttendanceLog(false);
        setShowComment(false);
        setCurrentRowData(null);
        setShowMeetingModal(false);
    };

    const handleRosterModalClose = () => setShowMemberDetails(false);

    const handleCaregiverModalClose = () => setOpenCaregiverModal(false);

    const handleInputChange = (name, value) => {
        let data = recruitmentStatisticsData[0];
        if (
            data.cohortStatus === COHORT_STATUS.ARCHIVED &&
            name === 'cohortId' &&
            value !== data.cohortId
        ) {
            let cohortOptions = cohortIdOptions.filter(cohort => cohort.id !== data.cohortId);
            setCohortIdOptions(cohortOptions);
        }
    }

    const onSubmit = (data) => {
        setIsLoading(true);

        const submitData = {
            ...data,
            popCharCore: recruitmentStatisticsData[0]?.popCharCore,
            popCharLanguage: recruitmentStatisticsData[0]?.popCharLanguage,
            popCharGeoDispersion: recruitmentStatisticsData[0]?.popCharGeoDispersion,
            popCharHealthAccess: recruitmentStatisticsData[0]?.popCharHealthAccess,
            id: recruitmentStatisticsData[0]?.id,
            userId: currentUser?.id,
            companyId: currentUser?.companyId
        }

        try {
            dispatch(updateProgram(submitData, () => {
                setIsLoading(false);
                showAlert(messages.success.updateAdvisoryGroupDetail, ALERT_TYPE.SUCCESS);
                handleAdvisoryGroupModalClose();
                api.GetMasterById(agId).then((res) => {
                    setRecruitmentStatisticsData(res.data)
                });
            }));
        } catch (error) {
            setIsLoading(false);
            showAlert(`${messages.error.updateAdvisoryGroup} ${error}.`, ALERT_TYPE.ERROR);
        }
    }

    const generateData = () => {
        api.GetMasterById(agId).then((res) => {
            setRecruitmentStatisticsData(res.data)
        });
        api.GetRepScoresByAgId(agId).then((res) => {
            setRepScoreData(res.data)
        });
        setDocumentData([]);
    }

    const recruitmentStatisticsColumn = [
        {
            id: 'shortName',
            header: 'Advisory Group Name',
            accessorKey: 'shortName',
            sortingFn: 'text'
        },
        {
            id: 'market',
            header: 'Market',
            accessorKey: 'market',
        },
        {
            id: 'targetSize',
            header: 'Target Size',
            accessorKey: 'targetSize',
        },
        {
            id: 'memberCount',
            header: '# of Active Members',
            accessorKey: 'activeMemberCount',
        },
        {
            id: 'noOfOpenApps',
            header: '# of Active Apps',
            accessorKey: 'appCount',
        },
        {
            id: 'noOfOpenInvitations',
            header: '# of Open Invitations',
            accessorKey: 'openInvitationCount',
        },
        {
            name: 'actions',
            header: 'Actions',
            size: 90,
            cell: ({ row }) => (
                <GridButton variant="light" size="sm" className="me-1 my-1" title="View"
                    onClick={() => navigate(`/advisory-group-management/operations/application-detail`, { state: { agId: row.original.id } })}>
                    <Icon icon="view" size={14} />
                </GridButton>
            )
        },
    ];

    const rosterColumn = [
        {
            id: 'firstName',
            header: 'First Name',
            accessorKey: 'firstName',
            sortingFn: 'text'
        },
        {
            id: 'lastName',
            header: 'Last Name',
            accessorKey: 'lastName',
        },
        {
            id: 'meetingDate',
            header: 'Last Meeting Attended',
            accessorKey: 'meetingDate',
            type: 'date',
            sortType: 'date',
            accessorFn: (row) => (new Date(row.meetingDate)),
            cell: ({ row }) => `${toDate(row.original.meetingDate)}`,
        },
        {
            id: 'attendancePercentage',
            header: 'Attendance Percentage',
            accessorFn: row => `${calculateAttendancePercentage(row.attendedAttendance, row.totalAttendance)}%`,
            sortingFn: 'alphanumeric',
        },
        {
            id: 'actions',
            header: 'Actions',
            size: 90,
            cell: ({ row }) => (
                <>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Caregiver Management"
                        onClick={() => { handleCaregiverModalOpen(row.original) }}>
                        <Icon icon={"caregiver-management"} size={14} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Member Details"
                        onClick={() => { handleMemberDetailsOpen(row.original) }}>
                        <Icon icon={"member-details"} size={14} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Attendance Log"
                        onClick={() => { handleAttendanceLogOpen(row.original) }}>
                        <Icon icon={"attendance-log"} size={14} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="View Comments"
                        onClick={() => { handleCommentOpen(row.original) }}>
                        <Icon icon={"comments"} size={14} />
                    </GridButton>
                </>
            )
        }
    ];

    const defaultSortMeets = [{
        id: 'meetingDate',
        desc: true,
    }];
    const meetingsColumn = [
        {
            id: 'meetingDate',
            header: 'Meeting Date',
            accessorKey: 'meetingDate',
            sortType: 'date',
            accessorFn: (row) => (new Date(row.meetingDate)),
            cell: ({ row }) => `${toDate(row.original.meetingDate)}`,
        },
        {
            id: 'title',
            header: 'Meeting Title',
            accessorKey: 'title',
        },
        {
            id: 'status',
            header: 'Meeting Status',
            accessorKey: 'status',
            accessorFn: (row) => `${AG_MEETING_STATUS[row.status]}`,
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id} label={AG_MEETING_STATUS[row.original.status]}>
                    {AG_MEETING_STATUS[row.original.status]}
                </OverflowText>
            ),
        },
        {
            name: 'actions',
            header: 'Actions',
            size: 90,
            cell: ({ row }) => (
                <GridButton variant="light" size="sm" className="me-1 my-1" title={"View"}
                    onClick={() => navigate(`/advisory-group-management/operations/meeting-details/${row.original.id}/${row.original.aGId}`)}>
                    <Icon icon={"view"} size={14} />
                </GridButton>
            )
        },
    ];
    const repScoreColumn = [
        {
            id: 'modifiedDateTime',
            header: 'Date',
            accessorKey: 'modifiedDateTime',
            sortingFn: 'date',
            sortType: 'date',
            accessorFn: (row) => (new Date(row.modifiedDateTime)),
            cell: ({ row }) => `${toDate(row.original.modifiedDateTime)}`,
        },
        {
            id: 'type',
            header: 'Scorecard Type',
            accessorKey: 'type',
            accessorFn: (row) => `${AG_REPSCORE_TYPE_VALUE[row.type]}`,
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id} label={AG_REPSCORE_TYPE_VALUE[row.original.type]}>
                    {AG_REPSCORE_TYPE_VALUE[row.original.type]}
                </OverflowText>
            ),
        },
        {
            id: 'aggregateScore',
            header: 'Aggregate Score',
            accessorKey: 'aggregateScore',
            accessorFn: (originalRow) => AGGREGATE_SCORES[originalRow.aggregateScore],
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    <div className={`px-2 ${AGGREGATE_SCORES_COLOR[row.original.aggregateScore]}`}>
                        {AGGREGATE_SCORES[row.original.aggregateScore]}
                    </div>
                </OverflowText>
            ),
        },
        {
            name: 'actions',
            header: 'Actions',
            size: 90,
            cell: ({ row }) => (
                <GridButton variant="light" size="sm" className="me-1 my-1" title={"View"}
                    onClick={() => { handleRepScoreOpen(row.original) }}>
                    <Icon icon={"view"} size={14} />
                </GridButton>
            )
        },
    ];
    const documentColumn = [
        {
            id: 'document',
            header: 'Document',
            accessorKey: 'document',
        },
        {
            id: 'documentName',
            header: 'Document Name',
            accessorKey: 'documentName',
        },
        {
            id: 'documentType',
            header: 'Document Type',
            accessorKey: 'documentType',
        },
        {
            name: 'actions',
            header: 'Actions',
            size: 90,
            cell: () => (
                <GridButton variant="light" size="sm" className="me-1 my-1" title={"View"}>
                    <Icon icon={"view"} size={14} />
                </GridButton>
            )
        },
    ];
    const typeOptions = optionValues(PROGRAM_TYPE);
    const marketOptions = optionValues(US_STATE);
    const meetingFrequecyOptions = optionValues(PROGRAM_MEETING_FREQUENCY);
    const meetingTypeOptions = optionValues(PROGRAM_MEETING_TYPE);
    const meetingStatusOptions = optionValues(PROGRAM_STATUS);

    const arr = [
        { key: 'name', labelName: 'Advisory Group Name', placeholderName: 'Maximum 32 characters', type: 'text', maxLength: 32 },
        { key: 'shortName', labelName: 'Short Advisory Group Name', placeholderName: 'Maximum 8 characters', type: 'text', maxLength: 8 },
        { key: 'type', labelName: 'Advisory Group Type', placeholderName: '', type: 'options', optionValues: typeOptions, disabled: true },
        { key: 'market', labelName: 'Market', placeholderName: '', type: 'options', optionValues: marketOptions },
        { key: 'status', labelName: 'Advisory Group Status', placeholderName: '', type: 'options', optionValues: meetingStatusOptions },
        { key: 'projectManagerId', labelName: 'Project Manager', placeholderName: '', type: 'options', optionValues: projectManagerIdOptions },
        { key: 'cohortId', labelName: 'Cohort', placeholderName: '', type: 'options', optionValues: cohortIdOptions, disabled: true },
        { key: 'targetSize', labelName: 'Advisory Group Target Size', placeholderName: '', type: 'number', maxValue: 9999 },
        { key: 'meetingFrequency', labelName: 'Meeting Frequency', placeholderName: '', type: 'options', optionValues: meetingFrequecyOptions },
        { key: 'meetingType', labelName: 'Meeting Type', placeholderName: '', type: 'options', optionValues: meetingTypeOptions },
    ]

    const eacFieldsArr = [
        { key: 'eacPlanName', labelName: 'EAC Plan Name', placeholderName: 'Maximum 32 characters', type: 'text', maxLength: 32 },
        { key: 'eacPlanContract', labelName: 'EAC Plan Contract', placeholderName: 'Maximum 16 characters', type: 'text', maxLength: 16 },
        { key: 'eacPlanPDBId', labelName: 'EAC Plan PBP ID', placeholderName: 'Maximum 16 characters', type: 'text', maxLength: 16 },
    ];

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            generateData();
            dispatch(getCohorts(currentUser?.companyId));
            dispatch(getRosterByAgId(agId));
            dispatch(getCompanyUsersById(currentUser?.companyId));
        }
    }, [dispatch, isAppLoaded, currentUser]);

    useEffect(() => {
        if (recruitmentStatisticsData && cohortsData && cohortsData.length > 0) {
            const statuses = [COHORT_FILE_STATUS.ENRICHED, COHORT_FILE_STATUS.SCHEDULE, COHORT_FILE_STATUS.VALIDATED];
            let data = recruitmentStatisticsData[0];
            let archivedCohort = [];
            if (data && data.cohortStatus === COHORT_STATUS.ARCHIVED) {
                // add archived cohort for display
                archivedCohort = [{ id: data?.cohortId, name: `${data?.cohortName} (archived)` }];
            }
            let cohortsDataWithBlankValue = [{ id: '', name: 'Select Cohort' }, ...archivedCohort, ...cohortsData.filter(item => statuses.includes(item.status))];
            setCohortIdOptions(cohortsDataWithBlankValue?.map(item => ({ id: item.id, value: item.name })));
        }
    }, [cohortsData, recruitmentStatisticsData]);


    useEffect(() => {
        if (companyUserData && companyUserData.length > 0) {

            const matchIndex = companyUserData.findIndex(item => item.id === currentUser?.id);
            let reorderedProjectManagerIdOptions = matchIndex !== -1
                ? [companyUserData[matchIndex], ...companyUserData.slice(0, matchIndex), ...companyUserData.slice(matchIndex + 1)]
                : companyUserData;
            reorderedProjectManagerIdOptions = reorderedProjectManagerIdOptions.filter(item => item.recordStatus !== USER_STATUS_VALUE.Archived);

            setProjectManagerIdOptions([...reorderedProjectManagerIdOptions.map(item => ({ id: item.id, value: item.name }))] ?? []);
        }
    }, [companyUserData]);

    useEffect(() => {
        if (isAppLoaded) {
            !showMeetingModal && dispatch(getMeetingDetailsByAgId(agId));
            console.log(nonMembers);
        }
    }, [isAppLoaded, showMeetingModal]);

    return (
        <div>
            <ActionBar title="Advisory Group Details" />
            {recruitmentStatisticsData && recruitmentStatisticsData.length > 0 && (
                <AdvisoryGroupInfo
                    info={recruitmentStatisticsData[0]}
                    onEdit={handleAdvisoryGroupModalOpen}
                    onAddNonMember={handleAddNonMemberRepModalOpen}
                />
            )}

            <div className="mb-4">
                <ActionBar title="Recruitment Statistics" />
                {recruitmentStatisticsData === null ? (
                    <Loader />
                ) : (
                    <DataGrid
                        data={recruitmentStatisticsData}
                        columns={recruitmentStatisticsColumn}
                        enableFilters={false}
                    />
                )}
            </div>

            <Row className="mb-4 g-xl-40">
                <Col md={6}>
                    <ActionBar title="Roster" />
                    {rosterMemberDetails === null ? (
                        <Loader />
                    ) : (
                        <DataGrid
                            data={rosterMemberDetails}
                            columns={rosterColumn}
                            enableFilters={false}
                        />
                    )}
                </Col>
                <Col md={6}>
                    <AgOpsNonMembers
                        agId={agId}
                        currentUser={currentUser}
                    />
                </Col>
            </Row>

            <Row className="mb-4 g-xl-40">
                <Col md={6}>
                    <div>
                        <ActionBar title="Meetings">
                            <Button
                                type="button"
                                onClick={() => setShowMeetingModal(true)}
                                className="px-4">
                                New Meeting
                            </Button>
                        </ActionBar>
                        {agMemberMeetingDetails === null ? (
                            <Loader />
                        ) : (
                            <DataGrid
                                data={agMemberMeetingDetails}
                                columns={meetingsColumn}
                                enableFilters={false}
                                sort={defaultSortMeets}
                            />
                        )}
                    </div>
                </Col>
                <Col
                    className="d-flex flex-column"
                    md={6}>
                    <div>
                        <ActionBar title="Scorecard - Last Baseline Date" />
                        {repScoreData === null ? (
                            <Loader />
                        ) : (
                            <DataGrid
                                data={repScoreData}
                                columns={repScoreColumn}
                                enableFilters={false}
                            />
                        )}
                    </div>
                </Col>
            </Row>

            <div className="mb-4">
                <ActionBar title="Documents" />
                {documentData === null ? (
                    <Loader />
                ) : (
                    <DataGrid
                        data={documentData}
                        columns={documentColumn}
                        enableFilters={false}
                    />
                )}
            </div>



            {currentRowData && <ProgramModal
                arrObj={currentRowData?.type === PROGRAM_TYPE_ABBR.EAC ? [...arr, ...eacFieldsArr] : arr}
                show={showAdvisoryGroupModal}
                onCancel={handleAdvisoryGroupModalClose}
                action={FORM_ACTION_TYPE.Edit}
                data={currentRowData}
                onInputChange={handleInputChange}
                onSubmit={onSubmit}
                onSubmitLoading={isLoading}
            />}

            {addNonMemberRepModal && (
                <AddNonMemberRepModal
                    action={FORM_ACTION_TYPE.New}
                    show={addNonMemberRepModal}
                    info={{ agId: agId }}
                    onCancel={handleAddNonMemberRepModalClose}
                />
            )}

            <RepScoreModal
                show={showRepScore}
                onCancel={handleAdvisoryGroupModalClose}
                data={currentRowData}
                viewOnly={true}
            />

            <CaregiverManagementModal
                show={openCaregiverModal}
                onCancel={handleCaregiverModalClose}
                data={currentRowData}
            />

            <AttendanceLogDetailsModal
                show={showAttendanceLog}
                onCancel={handleAdvisoryGroupModalClose}
                data={currentRowData}
                viewOnly={true}
            />

            <CommentModal
                show={showComment}
                onCancel={handleAdvisoryGroupModalClose}
                data={currentRowData}
                viewOnly={true}
            />

            <MemberDetailsModal
                show={showMemberDetails}
                onCancel={handleRosterModalClose}
                data={currentRowData}
            />

            <NewMeetingModal
                show={showMeetingModal}
                onCancel={handleAdvisoryGroupModalClose}
                data={
                    recruitmentStatisticsData?.length > 0 &&
                    {
                        id: recruitmentStatisticsData[0].id,
                        companyId: recruitmentStatisticsData[0].companyId,
                        shortName: recruitmentStatisticsData[0].shortName,
                    }
                }
            />
        </div>
    );
}

export default withAuth(OperationDetails);
