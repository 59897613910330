import React from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from "react-bootstrap";
import styles from '../meeting-details/MeetingDetails.module.scss';
import { PROGRAM_TYPE, PROGRAM_MEETING_FREQUENCY, PROGRAM_MEETING_TYPE } from '../../../../constants';

const AdvisoryGroupInfo = ({ info, onEdit, onAddNonMember }) => {
    const navigate = useNavigate();

    return (
        <div className="bg-secondary text-white p-4 mb-4">
            <Row className="mx-4 mx-xl-5 justify-content-around">
                <Col md={4} xl={3} className={styles.details}>
                    <h4 className={`${styles.subTitle} fw-bold`}>Advisory Group Name</h4>
                    <p>{info.name}</p>
                </Col>
                <Col md={4} xl={3} className={styles.details}>
                    <h4 className={`${styles.subTitle} fw-bold`}>Advisory Group Short Name</h4>
                    <p>{info.shortName}</p>
                </Col>
                <Col md={4} xl={3} className={styles.details}>
                    <h4 className={`${styles.subTitle} fw-bold`}>Advisory Group Type</h4>
                    <p>{PROGRAM_TYPE[info.type]}</p>
                </Col>
                <Col md={12}></Col>
                <Col md={4} xl={3} className={styles.details}>
                    <h4 className={`${styles.subTitle} fw-bold`}>Market</h4>
                    <p>{info.market}</p>
                </Col>
                <Col md={4} xl={3} className={styles.details}>
                    <h4 className={`${styles.subTitle} fw-bold`}>Status</h4>
                    <p>{info.status}</p>
                </Col>
                <Col md={4} xl={3} className={styles.details}>
                    <h4 className={`${styles.subTitle} fw-bold`}>Cohort Name</h4>
                    <p>{info.cohortName}</p>
                </Col>
                <Col md={12}></Col>
                <Col md={4} xl={3} className={styles.details}>
                    <h4 className={`${styles.subTitle} fw-bold`}>Target Size</h4>
                    <p>{info.targetSize}</p>
                </Col>
                <Col md={4} xl={3} className={styles.details}>
                    <h4 className={`${styles.subTitle} fw-bold`}>Meeting Frequency</h4>
                    <p>{PROGRAM_MEETING_FREQUENCY[info.meetingFrequency]}</p>
                </Col>
                <Col md={4} xl={3} className={styles.details}>
                    <h4 className={`${styles.subTitle} fw-bold`}>Meeting Type</h4>
                    <p>{PROGRAM_MEETING_TYPE[info.meetingType]}</p>
                </Col>
            </Row>
            <div className={`${styles.meetingActions} d-flex justify-content-center flex-wrap gap-2 mt-1`}>
                <Button
                    variant="primary"
                    // className="px-4"
                    onClick={onEdit}>
                    Edit
                </Button>
                <Button
                    variant="primary"
                    onClick={() => navigate('/advisory-group-management/operations/support-staff')}>
                    Support Staff
                </Button>
                <Button
                    variant="primary"
                    onClick={onAddNonMember}>
                    Non-Member Reps
                </Button>
            </div>
        </div>
    );
}

export default AdvisoryGroupInfo;
