import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import SharedForm from "../../../../components/ui/Form";
import {
    ALERT_TYPE,
    ALIGN,
    MIME_TYPES,
    PARKING_STATUS,
    POSITIONS,
    RECORD_STATUS_ABBR,
    US_STATE, VALIDATIONS
} from "../../../../constants";
import { optionValues, isValidName, dataURLtoFile, getNameList } from "../../../../utils/utils";
import { AppLoadContext } from "../../../../components/ui/AppLoadContext";
import { AlertContext } from "../../../../context/AlertContext";
import {
    addAdvisoryGroupMeetingLocation,
    getAdvisoryGroupMeetingLocationById, getAdvisoryGroupMeetingLocationContracts, resetLocationAndContractDetails,
    updateAdvisoryGroupMeetingLocation
} from "../../../../ducks/advisoryGroup";
import messages from "../../../../utils/helper/messages";
import Loader from "../../../../components/ui/Loader";

const AddLocationModal = ({ show, id, agInfo, onCancel, onAdd, hostData, viewOnly }) => {
    const dispatch = useDispatch();
    const isAppLoaded = useContext(AppLoadContext);
    const { showAlert } = useContext(AlertContext);

    const currentUser = useSelector((state) => state.user.currentUser);
    const locationDetail = useSelector((state) => state.advisoryGroup.currentLocationDetail);
    const contractDetail = useSelector((state) => state.advisoryGroup.currentContractDetail);
    const meetingLocationSupportStaff = useSelector(state => state.advisoryGroup?.meetingLocationSupportStaffDetails);

    const [isLoading, setIsLoading] = useState(false);
    const [isContentLoading, setIsContentLoading] = useState(false);
    const [locationDetails, setLocationDetails] = useState({ parking: 'M', state: US_STATE.AL });

    const parkingStatusOptions = optionValues(PARKING_STATUS);
    const stateOptions = optionValues(US_STATE);

    const meetingLocationSupportStaffOptions = optionValues(getNameList(meetingLocationSupportStaff), true);

    const arr = [
        {
            key: 'name',
            labelName: 'Meeting Location Name',
            placeholderName: 'Meeting Location Name',
            type: 'text',
            maxLength: 35
        },
        {
            key: 'address',
            labelName: 'Meeting Location Address',
            placeholderName: 'Meeting Location Address',
            type: 'text',
            maxLength: 65
        },
        {
            key: 'staffId',
            labelName: 'Host Name',
            placeholderName: 'Host Name',
            type: 'options',
            optionValues: meetingLocationSupportStaffOptions,
            required: false
        },
        {
            key: 'city',
            labelName: 'City',
            placeholderName: 'City',
            type: 'text',
            maxLength: 35
        },
        {
            key: 'state',
            labelName: 'State',
            placeholderName: 'State',
            type: 'options',
            optionValues: stateOptions,
            maxLength: 2,
            minLength: 2
        },
        {
            key: 'zipCode',
            labelName: 'Zip',
            placeholderName: 'Zip',
            type: 'text',
            minLength: 5,
            maxLength: 9,
            valid: VALIDATIONS.Number.isZipCode,
        },
        {
            key: 'contactName',
            labelName: 'Contact Name',
            placeholderName: 'Contact Name',
            type: 'text',
            maxLength: 35
        },
        {
            key: 'email',
            labelName: 'Email',
            placeholderName: 'Email',
            type: 'email',
            required: false,
            maxLength: 35
        },
        {
            key: 'phone',
            labelName: 'Phone',
            placeholderName: 'Phone',
            type: 'tel',
            maxLength: 10,
            required: false
        },
        {
            key: 'parking',
            labelName: 'Parking',
            placeholderName: 'Select Parking Status',
            type: 'options',
            optionValues: parkingStatusOptions
        },
        {
            key: 'contract',
            labelName: 'Upload Contract',
            type: 'file',
            allowedTypes: [MIME_TYPES.PDF],
            fileNameKey: 'fileName',
            fileURLKey: 'contract',
            required: false,
        },
        {
            key: 'contract2',
            labelName: 'Upload Secondary Contract',
            type: 'file',
            allowedTypes: [MIME_TYPES.PDF],
            fileNameKey: 'fileName2',
            fileURLKey: 'contract2',
            required: false,
        },
        {
            key: 'comment',
            labelName: 'Comments',
            placeholderName: 'Comments',
            type: 'textarea',
            required: false,
            maxLength: 255,
            valid: VALIDATIONS.String.noSpecialChars,
        },
    ];

    const handleLocationDetailsChange = (update) => {
        setLocationDetails((details) => ({ ...details, ...update }))
    }

    const onHideCallback = () => {
        setLocationDetails(null);
        dispatch(resetLocationAndContractDetails());
        onCancel();
    }

    const handleInputChange = (name, value) => {
        switch (name) {
            case "contract":
                handleLocationDetailsChange({
                    contract: value,
                    fileName: value.name
                });
                break;
            case "contract2":
                handleLocationDetailsChange({
                    contract2: value,
                    fileName2: value.name
                });
                break;
            default:
                handleLocationDetailsChange({ [name]: value });
        }
    }

    const handleSuccess = () => {
        setIsLoading(false);
        showAlert(id ? messages.success.updateMeetingLocation : messages.success.addMeetingLocation, ALERT_TYPE.SUCCESS);
        onAdd();
        onCancel();
    }

    const handleError = (error) => {
        setIsLoading(false);
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    }

    const handleSubmit = async () => {
        setIsLoading(true);

        const address1 = locationDetails.address.indexOf(',') ? locationDetails.address.split(',')[0] : locationDetails.address;
        const address2 = locationDetails.address.indexOf(',') ? locationDetails.address.split(',')[1] : null;
        const submitData = {
            ...agInfo,
            name: locationDetails.name,
            address1: address1,
            address2: address2,
            city: locationDetails.city,
            state: locationDetails.state,
            zipCode: locationDetails.zipCode,
            contactName: locationDetails.contactName,
            email: locationDetails.email,
            phone: locationDetails.phone,
            parking: locationDetails.parking,
            contract: dataURLtoFile(locationDetails.contract, locationDetails.fileName ? locationDetails.fileName : null),
            contract2: dataURLtoFile(locationDetails.contract2, locationDetails.fileName2 ? locationDetails.fileName2 : null),
            comment: locationDetails.comment,
            status: RECORD_STATUS_ABBR.Operational,
            supportStaffId: locationDetails?.staffId ? locationDetails.staffId : null
        }

        try {
            if (id) {
                dispatch(updateAdvisoryGroupMeetingLocation({ ...submitData, id: id }, handleSuccess, handleError));
            } else {
                dispatch(addAdvisoryGroupMeetingLocation(submitData, handleSuccess, handleError));
            }
        } catch (error) {
            handleError(error);
        }
    }

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            if (id) {
                setIsContentLoading(true);
                dispatch(getAdvisoryGroupMeetingLocationById(id,
                    () => setIsContentLoading(false),
                    () => setIsContentLoading(false)
                ))
            }
        }
    }, [isAppLoaded, currentUser, dispatch]);

    useEffect(() => {
        if (id && locationDetail) {
            setLocationDetails({
                ...locationDetail,
                address: `${locationDetail.address1 ?? ''}${isValidName(locationDetail.address2) ? ',' + locationDetail.address2 : ''}`,
                hostName: hostData?.hostName
            });
            dispatch(getAdvisoryGroupMeetingLocationContracts(id));
        }
    }, [locationDetail]);

    useEffect(() => {
        if (contractDetail && locationDetails) {
            setLocationDetails({
                ...locationDetails,
                ...contractDetail,
            });
        }
    }, [contractDetail]);

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onHideCallback}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>{viewOnly ? 'View' : (id ? 'Edit' : 'Add')} Location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-2 py-xl-2">
                    {isContentLoading && <Loader />}
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={locationDetails}
                        loading={isLoading}
                        onInputChanged={handleInputChange}
                        onSubmit={handleSubmit}
                        onCancel={onHideCallback}
                        disabled={locationDetails.name ? false : true}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText="Save"
                        formStyle="modal-with-scroll overflow-x-hidden overflow-y-auto gx-5 py-3 mb-n3 border-top border-1"
                        viewOnly={viewOnly}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AddLocationModal;
