import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import SharedForm from '../../../../components/ui/Form';
import {
    AG_MEETING_STATUS_INVERSE, AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS,
    AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST,
    AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST_VALUE,
    AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_VALUE, ALERT_TYPE,
    ALIGN,
    POSITIONS,
    SUPPORT_STAFF_ROLE,
} from '../../../../constants';
import { dropdownValues, isObjectEmpty, omitProps, optionValues, pickProps } from '../../../../utils/utils';
import api from '../../../../services/api/advisoryGroupService';
import { AlertContext } from '../../../../context/AlertContext';
import { getSupportStaffAssignedList } from '../../../../ducks/advisoryGroup';
import messages from '../../../../utils/helper/messages';

const EditMeetingSupportStaffModal = ({ show, data, locations, meeting, onCancel }) => {
    const dispatch = useDispatch();
    const { showAlert } = useContext(AlertContext);

    const [locationOptions, setLocationOptions] = useState(null);
    const [supportStaffData, setSupportStaffData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const arr = [
        {
            key: 'firstName',
            labelName: 'First Name',
            type: 'text',
            disabled: true,
            required: false,
        },
        {
            key: 'lastName',
            labelName: 'Last Name',
            type: 'text',
            disabled: true,
            required: false,
        },
        {
            key: 'organization',
            labelName: 'Organization',
            type: 'text',
            disabled: true,
            required: false,
        },
        {
            key: 'role',
            labelName: 'Role',
            type: 'options',
            optionValues: optionValues(SUPPORT_STAFF_ROLE, true, 'Select Role'),
            disabled: true,
            required: false,
        },
        {
            key: 'cellPhone',
            labelName: 'Cellphone',
            type: 'tel',
            disabled: true,
            required: false,
        },
        {
            key: 'status',
            labelName: 'Pre-meeting Status',
            type: 'options',
            optionValues: optionValues(AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_VALUE),
            disabled: meeting.isTodayOrPast,
        },
        {
            key: 'statusPost',
            labelName: 'Post Meeting Status',
            type: 'options',
            optionValues: optionValues(
                meeting.status !== AG_MEETING_STATUS_INVERSE.Completed && !meeting.isTodayOrPast ?
                    pickProps(AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST_VALUE, AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST.NA) :
                    omitProps(AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST_VALUE, AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST.NA)
            ),
            disabled: !meeting.isTodayOrPast,
        },
        {
            key: 'locationId',
            labelName: 'Location',
            type: 'options',
            optionValues: locationOptions,
            required: false,
            disabled: meeting.isTodayOrPast,
        },
    ];

    const getSupportStaffPostStatus = (formData) => {
        if (data.status !== formData.status) {
            // if status is reverted from cancelled to confirmed or assigned, statusPost is reset to its default status - NA
            if (data.status === AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS.Cancelled) {
                return AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST.NA
            }
            // if status is changed to cancelled, statusPost is automatically changed to Cancelled
            if (formData.status === AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS.Cancelled) {
                return AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST.Cancelled
            }
        }
        return formData.statusPost ?? data.statusPost
    }

    const handleSubmit = (formData) => {
        setIsLoading(true);
        const submitData = {
            ...formData,
            id: data.attendanceLogId,
            status: formData.status ?? data.status,
            statusPost: getSupportStaffPostStatus(formData),
            locationId: formData.locationId === "" ? null : formData.locationId,
        }

        api.UpdateSupportStaffAttendanceLog(submitData)
            .then(() => {
                showAlert(messages.success.updateSupportStaffAssigned, ALERT_TYPE.SUCCESS);
                setIsLoading(false);
                dispatch(getSupportStaffAssignedList(meeting.id));
                onCancel();
            })
            .catch(error => {
                setIsLoading(false);
                showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
            })
    }

    const isValidStaffInfo = () => {
        return !isObjectEmpty(supportStaffData);
    }

    useEffect(() => {
        if (locations && locations.length > 0) {
            const options = dropdownValues(locations, 'name', 'id', true, 'Select Location');
            setLocationOptions(options);
        }
    }, [locations]);

    useEffect(() => {
        if (data) {
            const editData = pickProps(data, [
                'firstName', 'lastName', 'organization', 'role', 'cellPhone', 'status', 'statusPost', 'locationId',
            ]);
            setSupportStaffData({
                ...editData,
                locationId: editData.locationId === null ? '' : String(editData.locationId),
                comment: "",
            });
        }
    }, [data]);

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onCancel}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>Edit Support Staff Attendance</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-2 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={supportStaffData}
                        loading={isLoading}
                        disabled={!isValidStaffInfo()}
                        onSubmit={handleSubmit}
                        onCancel={onCancel}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText="Save"
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EditMeetingSupportStaffModal;
