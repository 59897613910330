import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import SharedForm from '../../../../components/ui/Form';
import { addNonMember, getNonMembersByAgId, updateNonMember } from '../../../../ducks/nonMembers';
import { AlertContext } from '../../../../context/AlertContext';
import messages from '../../../../utils/helper/messages';
import {
    AG_NON_MEMBER_RETIREMENT_REASON,
    AG_NON_MEMBER_STATUS,
    ALERT_TYPE,
    ALIGN, FORM_ACTION_TYPE, ONBOARDING_STATUS,
    POSITIONS,
} from '../../../../constants';
import { isObjectEmpty, optionValues } from '../../../../utils/utils';

const AddNonMemberRepModal = ({ show, action, info, data = null, onCancel }) => {
    const dispatch = useDispatch();
    const { showAlert } = useContext(AlertContext);

    const currentUser = useSelector((state) => state.user.currentUser);

    const [isLoading, setIsLoading] = useState(false);
    const [nonMemberDetails, setNonMemberDetails] = useState(null);

    const arr = [
        {
            key: 'firstName',
            labelName: 'First Name',
            type: 'text',
            maxLength: 35,
        },
        {
            key: 'lastName',
            labelName: 'Last Name',
            type: 'text',
            maxLength: 35,
        },
        { key: 'email', labelName: 'Email', type: 'email', maxLength: 25 },
        { key: 'phone', labelName: 'Phone', type: 'tel', maxLength: 10 },
        {
            key: 'status',
            labelName: 'Status',
            type: 'options',
            optionValues: optionValues(AG_NON_MEMBER_STATUS, true),
        },
        {
            key: 'startDate',
            labelName: 'Start Date',
            type: 'date',
            disabled: action === FORM_ACTION_TYPE.Edit,
            required: false,
        },
        {
            key: 'communicationLastDate',
            labelName: 'Communication Last Date',
            type: 'date',
            disabled: action === FORM_ACTION_TYPE.Edit,
            required: false,
        },
        {
            key: 'onboarding',
            labelName: 'Onboarding Status',
            type: 'options',
            optionValues: optionValues(ONBOARDING_STATUS, true),
            required: false,
        },
        {
            key: 'docCode',
            labelName: 'Doc Code',
            type: 'text',
            maxLength: 4,
            required: false,
        },
        {
            key: 'retireDate',
            labelName: 'Retire Date',
            type: 'date',
            required: false,
            conditional: true,
            disabled: true,
            dependsOn: 'status',
            dependsValue: [AG_NON_MEMBER_STATUS.Retired],
        },
        {
            key: 'retirementReason',
            labelName: 'Retirement Reason',
            type: 'options',
            optionValues: optionValues(AG_NON_MEMBER_RETIREMENT_REASON, true),
            required: false,
            conditional: true,
            disabled: true,
            dependsOn: 'status',
            dependsValue: [AG_NON_MEMBER_STATUS.Retired],
        },
    ];

    const handleSuccess = () => {
        setIsLoading(false);
        showAlert(FORM_ACTION_TYPE.Edit ? messages.success.updateNonMember : messages.success.addNonMember , ALERT_TYPE.SUCCESS);
        dispatch(getNonMembersByAgId(info.agId));
        onCancel();
    }

    const handleError = (error) => {
        setIsLoading(false);
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    }

    const handleSubmit = (formData) => {
        setIsLoading(true);

        if (action === FORM_ACTION_TYPE.New) {
            const addData = {
                ...formData,
                agId: info.agId,
                companyId: currentUser?.companyId,
                onboarding: formData.onboarding === '' ? null : formData.onboarding,
                lastUsertoUpdateRecord: currentUser?.id,
            }
            dispatch(addNonMember(addData, handleSuccess, handleError));
        } else if (action === FORM_ACTION_TYPE.Edit) {
            const updateData = {
                ...formData,
                id: info.id,
                onboarding: formData.onboarding === '' ? null : formData.onboarding,
                retireDate: (formData.status === AG_NON_MEMBER_STATUS.Retired && !data.memberRetireDate) ? new Date() : data.retireDate,
                retirementReason: formData.status === AG_NON_MEMBER_STATUS.Retired ? formData.retirementReason : null,
                lastUsertoUpdateRecord: currentUser?.id,
            }
            dispatch(updateNonMember(updateData, handleSuccess, handleError));
        }
    }

    const isValidNonMemberData = () => {
        if (action === FORM_ACTION_TYPE.Edit) {
            return !isObjectEmpty(nonMemberDetails);
        } else {
            return true
        }
    }

    useEffect(() => {
        if (data) {
            setNonMemberDetails(data);
        }
    }, [data]);

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onCancel}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>Non-Member Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={action === FORM_ACTION_TYPE.Edit ? nonMemberDetails : null}
                        onSubmit={handleSubmit}
                        onCancel={onCancel}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        loading={isLoading}
                        disabled={!isValidNonMemberData()}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AddNonMemberRepModal;
